<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="View Product Team">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Product Team ID</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" v-model="this.code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Product Team Description</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" v-model="this.name" disabled />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <input type="radio" id="Active" value="Active" v-model="status" />
            <label for="active">Active</label>

            <input
              type="radio"
              id="Inactive"
              value="Inactive"
              v-model="status"
            />
            <label for="inactive">Inactive</label>
          </div>
        </div>
        <br />
        <hr />
        <br />
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-col w-full">
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="items"
            :max-items="length"
            :total="total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th sort-key="sku">SKU</vs-th>
              <vs-th sort-key="name">Name</vs-th>
              <vs-th sort-key="category">Categories</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="itemsCode[indextr]">{{
                  itemsCode[indextr]
                }}</vs-td>
                <vs-td :data="itemsName[indextr]">{{
                  itemsName[indextr]
                }}</vs-td>
                <vs-td :data="itemCategories[indextr]">{{
                  itemCategories[indextr]
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            style="padding-top: 5px"
            :total="totalPage"
            v-model="setPage"
          />
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  components: {},
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    this.getProductItems();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    search: function () {
      this.getProductItems();
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    itemsCode: [],
    itemsName: [],
    itemsUom: [],
    itemUnitID: [],
    itemCategories: [],

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: {},
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    itemSearch: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    name: "",
    code: "",
    CompanyID: 0,
    ProductItem: [],
    ID: 0,
    status: "Active",
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-teams",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.itemsCode = [];
      this.itemsName = [];
      this.product_teams_id = [];
      this.item_unit_id = [];
      this.itemUnitID = [];
      this.itemCategories = [];

      // this.itemsUom = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getProductItems();
      this.scrollToTop();
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getProductItems();
    },
    SubmitForm() {
      const id = this.$route.params.id;
      if (this.itemsCode.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      // this.$vs.loading();
      this.$http
        .put("/api/sfa/v1/product-teams/" + id + "/update", {
          code: this.code,
          name: this.name,
          description: this.descriptions,
          Company_id: this.CompanyID,
          item_unit_id: this.itemUnitID,
          ID: parseInt(this.$route.params.id),
          status: this.status,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.CancelForm();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          // this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getProductItems();
    },
    handleChangePage(page) {
      this.page = page;
      this.getProductItems();
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getProductItems();
    },
    getProductItems(page = 1) {
      this.isActive = page;
      this.id = this.$route.params.id;
      this.$http
        .get("/api/sfa/v1/product-teams/" + this.id + "/view", {
          params: {
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            console.log(">>>>>>>", resp.data);
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data.Data_Item;
            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemUnitID = [];
            this.code = resp.data.data_product_team.Code;
            this.name = resp.data.data_product_team.Name;
            this.descriptions = resp.data.data_product_team.Description;
            this.status = resp.data.data_product_team.Status;
            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.itemCategories.push(
                resp.data.Data_Item[index].categories_name
              );
              this.itemsCode.push(resp.data.Data_Item[index].sku_code);
              this.itemsName.push(resp.data.Data_Item[index].name);
              // this.itemsUom.push(resp.data.Data_Item[index].unit.Name);
              this.itemUnitID.push(resp.data.Data_Item[index].id);
            }
          }
        });
    },
  },
};
</script>
